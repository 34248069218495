import React from 'react'
import { useSwiper } from 'swiper/react';

import CartoonArrowLeft from 'images/cartoon-arrow-left.png';
import CartoonArrowRight from 'images/cartoon-arrow-right.png';

const SwiperNavButtons = () => {
  const swiper = useSwiper();

  return (
    <>
      <img
        src={CartoonArrowLeft}
        alt='Cartoon arrow left'
        onClick={() => swiper.slidePrev()}
        className='
          absolute
          top-1/2
          transform
          -translate-y-1/2
          z-20
          -left-[7%]
          w-[5%]
          cursor-pointer
        '
      />
      <img
        src={CartoonArrowRight}
        alt='Cartoon arrow right'
        onClick={() => swiper.slideNext()}
        className='
          absolute
          top-1/2
          transform
          -translate-y-1/2
          z-20
          -right-[7%]
          w-[5%]
          cursor-pointer
        '
      />
    </>
  )
}

export default SwiperNavButtons;
