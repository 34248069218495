import React, { FC, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Pagination } from 'swiper/modules';

import { ISlide, ISliderProps } from 'common/types';

import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage } from 'gatsby-plugin-image';
import Spinner from '@global/Spinner';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles.scss';

const SwipeSlider: FC<ISliderProps> = ({
  spaceBetween = 0,
  slidesPerView = 1,
  centeredSlides = false,
  pagination = false,
  navigation = false,
  loop = false,
  className = '',
  style = {},
  slides,
  children,
  setCurrentGallery = null,
}) => {
  const [videoReadyToPlay, setVideoReadyToPlay] = useState(false);

  const { t } = useTranslation();

  const handleTap = (slides: ISlide[], s: any) => {
    if (s.activeIndex !== s.clickedIndex) return;

    if(setCurrentGallery !== null) setCurrentGallery(slides)
  }

  return (
    <>
      <Swiper
        modules={[Pagination]}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        centeredSlides={centeredSlides}
        pagination={pagination}
        loop={loop}
        className={className}
        style={style}
        onTap={(s) => handleTap(slides[s.realIndex].screenshots, s)}
      >
        {
          slides.map((slide, index) => (
            <SwiperSlide key={index}>
              {
                slide.type === 'img'
                ? (
                    <GatsbyImage
                      image={slide.imageData}
                      alt={t(slide.title, {type: slide.altAid})}
                    />
                )
                : (
                  <>
                    <video
                      muted
                      autoPlay
                      loop
                      onCanPlay={() => setVideoReadyToPlay(true)}
                      className={`rounded-lg ${videoReadyToPlay ? 'block' : 'hidden'}`}
                      aria-label={t(slide.title, { type: slide.altAid })}
                    >
                      <source
                        src={slide.videoUrl}
                        type="video/mp4"
                      />
                    </video>
                    <Spinner className={`absolute transform top-1/2 translate-y-1/2 w-1/4 ${videoReadyToPlay ? 'hidden' : 'block'}`} />
                  </>
                )
              }
            </SwiperSlide>
          ))
        }
        {
          (navigation && slides.length > 1) && children
        }
      </Swiper>
    </>

  )
}

export default SwipeSlider;
