import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useMediaChange from 'common/useMediaChange';

import { COMMUNITY_SECTION_ID } from 'common/const'
import { ISectionQueryData } from 'common/types';
import { getBucketImage } from 'common/utils';

import { SocialBar } from '@global/SocialBar';
import { StaticImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';

import './styles.scss';

const JoinOurCommunity: FC<ISectionQueryData> = ({ imagesData }) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaChange();

  const joinOurCommunityBackground = [
    getBucketImage(imagesData, 'neon-light-desktop.png', 'fluidData'),
    {
      ...getBucketImage(imagesData, 'neon-lights-mobile.png', 'fluidData'),
      media: '(max-width: 1024px)',
    },
  ]

  return (
    <BackgroundImage
      Tag='section'
      fluid={joinOurCommunityBackground}
      id={COMMUNITY_SECTION_ID}
      className='join-our-community-background relative flex flex-col items-center'
    >
      <StaticImage
        src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/overlap-moken-top.png'
        alt={t('joinOurCommunitySection.images.mokenTop')}
        className='overlap-moken overlap-moken-top'
        placeholder='none'
      />
      <div className='w-full h-full flex justify-center items-center'>
        <div className='joc-safe-space safe-space-container'>
          <div className='joc-text-container'>
            <h1 className='joc-title'>
              {t('joinOurCommunitySection.title')}
            </h1>
            <p className='joc-description'>
              {t('joinOurCommunitySection.description')}
            </p>
            <div className='flex w-full justify-center'>
              <SocialBar
                size={isMobile ? 'sm' : 'lg'}
                linkedSocialMedia={[
                  'telegram',
                  'discord',
                  'x',
                  'instagram',
                  'tiktok',
                  'youtube',
                  'twitch',
                  'medium',
                  'linkedin'
                ]}
              />
            </div>
          </div>
          <StaticImage
            src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/moken-team-no-bg.png'
            alt={t('joinOurCommunitySection.images.mokensBackground')}
            className='joc-mokens-no-bg'
            placeholder='none'
          />
        </div>
      </div>
      <StaticImage
        src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/overlap-moken-bottom.png'
        alt={t('joinOurCommunitySection.images.mokenBottom')}
        className='overlap-moken overlap-moken-bottom'
        placeholder='none'
      />
    </BackgroundImage>
  )
}

export default JoinOurCommunity;
