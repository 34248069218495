import React, { useState } from 'react';
import useMediaChange from 'common/useMediaChange';
import { useTranslation } from "react-i18next";

import { FAQ_SECTION_ID } from 'common/const';
import { IFAQPanel } from 'common/types';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { HP } from '@global/Texts';
import { StaticImage } from 'gatsby-plugin-image';

import PlusIcon from 'images/plus.svg';
import MinusIcon from 'images/minus.svg';

import './styles.scss';

const FAQ = () => {
  const { isMobile } = useMediaChange();

  const { t } = useTranslation();
  const panels: IFAQPanel[] = t('faqSection.panels', { returnObjects: true });

  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const mobileHpStyle = {
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.25
  }

  const desktopHpStyle = {
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.25
  }

  return (
    <section
      id={FAQ_SECTION_ID}
      className='
        bg-gradient-to-t
        from-[#9E0606]
        via-[#E81C1A]
        to-[#9E1A99]
      '
    >
      <div className='relative flex flex-col items-center'>
        <div
          className='
            mt-[10%]
            mb-[30%]
            lg:w-[65%]
            lg:mb-[10%]
						safe-space-container
          '
        >
          <div className='relative flex justify-center items-end space-x-[1%] mb-[5%]'>
            <h1 className='faq-title'>
              { t('faqSection.title') }
            </h1>
            <StaticImage
              src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/moken-arg-juggling.png'
              alt={t('faqSection.images.juggleMokenAlt')}
              className='faq-moken-juggle'
              placeholder='none'
            />
          </div>
          {panels.map((panel, index) => {
            return (
              <Accordion
                key={index}
                square
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{
                  border: '3px solid white',
                  color: 'white',
                  margin: '-3px 0 0 0',
                  padding: `${!isMobile && '10px 20px'}`,
                  backgroundColor: `${expanded === `panel${index}` ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.2)'}`
                }}
              >
                <AccordionSummary
                  id={`panel${index}-header`}
                  expandIcon={
                    expanded === `panel${index}`
                      ? <img src={MinusIcon} alt={t('faqSection.icons.minus')} className='w-6 h-6' />
                      : <img src={PlusIcon} alt={t('faqSection.icons.plus')} className='w-6 h-6'/>
                  }
                >
                  <h3
                    className={
                      `
                        italic
                        ${expanded === `panel${index}` && 'font-bold'}
                        lg:text-[1.5rem]
                      `
                    }
                  >
                    {panel.header}
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    dangerouslySetInnerHTML={{ __html: panel.details }}
                    style={isMobile ? mobileHpStyle : desktopHpStyle}
                  />
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
        <StaticImage
          src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/football.png'
          alt={t('faqSection.images.footballAlt')}
          className='faq-football'
          placeholder='none'
        />
        <StaticImage
          src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/moken-brasil.png'
          alt={t('faqSection.images.brazilMokenAlt')}
          className='faq-moken-bottom'
          placeholder='none'
        />
        <StaticImage
          src='https://mokens-landing-bucket.s3.amazonaws.com/images/dividers/white-wedge.png'
          alt={t('faqSection.images.whiteDividerAlt')}
          className='aspect-[25.6] absolute bottom-0 w-full'
          placeholder='none'
        />
      </div>
    </section>
  )
}

export default FAQ;
