import React, { FC } from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';

import {
	ALBUM_SECTION_ID,
	TOURNAMENTS_SECTION_ID,
	LOGIN_URL,
	COLLECTION_URL,
	TOURNAMENTS_URL
} from 'common/const';
import { getBucketImage, navigateRedirect } from 'common/utils';
import { IAlbumAndTournament } from 'common/types';

import { StaticImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { StylizedButton } from '@global/Buttons';

import './styles.scss';

const AlbumAndTournaments: FC<IAlbumAndTournament> = ({ imagesData, isUserLogged }) => {
	const { t } = useTranslation();
	const { language, languages } = useI18next();

	return (
		<>
			<BackgroundImage
				Tag='section'
				id={ALBUM_SECTION_ID}
				fluid={getBucketImage(imagesData, 'collection-hero-section-background-desktop.png', 'fluidData')}
				className='landing-collection-section'
			>
				<div className='absolute w-full h-full left-0 top-0 -z-1 bg-black bg-opacity-60' />
				<div
					className='
            relative
            h-full
            flex
            flex-col
            items-center
            lg:py-0
            lg:flex-row
            lg:justify-center
          '
				>
					<div
						className='
							flex
							flex-col
							items-center
							space-y-8
							lg:flex-row
							lg:justify-between
							safe-space-container
						'
					>
						<StaticImage
							src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/mokens-electric-power.png'
							alt={t('albumSection.images.energyBurst')}
							objectFit='contain'
							placeholder='none'
							className='landing-album-tournaments-img landing-album-tournaments-img-energy'
						/>
						<div
							className='
								flex
								flex-col
								justify-center
								lg:w-1/2
								lg:items-end
							'
						>
							<h1 className='album-title album-title-hollow'>
								{t('albumSection.outlinedTitle')}
							</h1>
							<h1 className='album-title album-title-filled'>
								{t('albumSection.filledTitle')}
							</h1>
							<div className='text-white space-y-4 mt-4'>
								<p className='album-description'>
									{t('albumSection.paragraph.part1')}
								</p>
								<p className='album-description'>
									{t('albumSection.paragraph.part2')}
								</p>
							</div>
							<div className='flex justify-center mt-8 h-[60px] lg:w-full lg:justify-start'>
								<StylizedButton
									className='h-full button-long-text'
									text={t('albumSection.cta')}
									onClick={() => isUserLogged
										? navigate(COLLECTION_URL)
										: navigate(
											navigateRedirect(LOGIN_URL, language, languages),
											{ state: { redirectAfterLogin: `${location.origin}${COLLECTION_URL}` } }
										)
									}
								/>
							</div>
					</div>
					</div>
				</div>
			</BackgroundImage>
			<BackgroundImage
				Tag='section'
				id={TOURNAMENTS_SECTION_ID}
				fluid={getBucketImage(imagesData, 'purple-mokens-head-collage.png', 'fluidData')}
				className='landing-tournaments-section'
			>
				<div className='flex justify-center'>
					<div
						className='landing-tournaments-safe-space'
					>
						<div className='flex flex-col justify-center lg:w-1/2'>
							<div className='space-y-4 mt-4'>
								<h1 className='album-title album-title-hollow'>
									{t('tournamentsSection.title')}
								</h1>
								<p className='album-description'>
									{t('tournamentsSection.description')}
								</p>
							</div>
							<div className='flex justify-center mt-8 h-[60px] lg:w-full lg:justify-start'>
								<StylizedButton
									className='h-full button-long-text'
									link={TOURNAMENTS_URL}
									text={t('tournamentsSection.cta')}
								/>
							</div>
						</div>
						<StaticImage
							src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/mokens-chasing-ball-with-logo.png'
							alt={t('tournamentsSection.logoAlt')}
							placeholder='none'
							className='landing-tournaments-overlap-img'
						/>
					</div>
				</div>
			</BackgroundImage>
		</>
	);
};

export default AlbumAndTournaments;
