import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';
import { IDownloadButtonsProps } from 'common/types';
import { S3_BUCKET_URL } from 'common/const';

import AndroidIcon from 'images/android.svg';
import AppleIcon from 'images/apple.svg';
import WindowsIcon from 'images/windows.svg';

import DownloadButtons from './DownloadButtons';

const HeroSection = () => {
  const { t } = useTranslation();

  const [buttonsReady, setButtonsReady] = useState(false);

  const downloadButtons: IDownloadButtonsProps[] = [
    {
      text: 'Android',
      icon: AndroidIcon,
      url: 'https://play.google.com/store/apps/details?id=com.mokensleague.mobile.alpha'
    },
    {
      text: 'iOS',
      icon: AppleIcon,
      url: 'https://apps.apple.com/ar/app/mokens-league-soccer/id6446360237?l'
    },
    {
      text: 'Windows',
      icon: WindowsIcon,
      url: 'https://elixir.games/browse/mokens-league'
    }
  ]

  const { mobilePoster, desktopPoster } = useStaticQuery(graphql`
    query heroPosters {
      mobilePoster: file(relativePath: {eq: "hero/mobile-hero-video-poster.png"}) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      desktopPoster: file(relativePath: {eq: "hero/desktop-hero-video-poster.png"}) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)

  return (
    <section className='relative pt-20 lg:pt-0'>
      <video
        muted
        autoPlay
        loop
        playsInline
        className='w-full sm:hidden'
        src={S3_BUCKET_URL + 'videos/gameplay-mobile.mp4'}
        poster={mobilePoster.childImageSharp.fluid.src}
      />
      <video
        muted
        autoPlay
        loop
        playsInline
        className='w-full hidden sm:block'
        src={S3_BUCKET_URL + 'videos/gameplay.mp4'}
        poster={desktopPoster.childImageSharp.fluid.src}
      />
      <div className='absolute bottom-0 w-full h-full bg-black opacity-20' />
      <div
        className='
          absolute
          transform
          top-1/2
          -translate-y-1/2
          w-full
          flex
          flex-col
          justify-center
          items-center
        '
      >
        <StaticImage
          src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/mokens-football-icon.png'
          alt={t('heroSection.logoAlt')}
          loading='eager'
          placeholder='none'
          onLoad={() => setButtonsReady(true)}
          className='w-1/2 max-w-[200px] lg:w-[10%] lg:max-w-none lg:min-w-[150px]'
        />
        <DownloadButtons buttons={downloadButtons} ready={buttonsReady} />
      </div>
    </section>
  )
}

export default HeroSection;
