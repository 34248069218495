import React, { FC, useState } from 'react';
import useMediaChange from 'common/useMediaChange';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { MOKENS_UNIVERSE_SECTION_ID,S3_BUCKET_URL } from 'common/const';
import { ISectionQueryData, ISlide } from 'common/types';
import { getLocalImage } from 'common/utils';

import SwipeSlider from '@global/SwipeSlider';
import SwiperNavButtons from '@global/SwipeSlider/SwiperNavButtons';
import { IconButton, Modal } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';

import CloseIcon from 'images/close-red.svg';

const MokensUniverse: FC<ISectionQueryData> = ({ imagesData }) => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedGallery, setSelectedGallery] = useState<ISlide[]>([]);
  const [canClose, setCanClose] = useState<boolean>(false);

  const { isMobile } = useMediaChange();

  const setModalState = (state: boolean) => {
    setModalOpen(state);
  }

  const openGallery = (slides: ISlide[]) => {
    setSelectedGallery(slides);
    handleOpen();
  }

  // Added due to the modal closing immediately upon opening if the user clicks to open the gallery and the mouse is over the dark background
  const handleOpen = () => {
    setCanClose(false);
    setModalState(true);
    setTimeout(() => {
      setCanClose(true);
    }, 100);
  }

  const handleClose = (event: object, reason: string) => {
    if (reason && reason == "backdropClick" && !canClose) return;
    setModalState(false);
  }

  const sliderData: ISlide[] = [
  {
    type: 'img',
    imageData: getLocalImage(imagesData, 'mokens-universe/stadiumLogos/Mokens-arena-staduim.png', 'imageData'),
    title: 'mokensUniverse.stadiums.mokensArena',
    altAid: language === 'en' ? 'logo' : 'Logo del',
    screenshots: [
      {
        type: 'video',
        videoUrl: S3_BUCKET_URL + 'videos/MokensArenaTurnaround.mp4',
        title: 'mokensUniverse.stadiums.mokensArena',
        altAid: language === 'en' ? 'video' : 'Video del'
      },
      {
        type: 'img',
        imageData: getLocalImage(imagesData, 'mokens-universe/stadiumPreviews/MokensArenaPreview.png', 'imageData'),
        title: 'mokensUniverse.stadiums.mokensArena',
        altAid: language === 'en' ? 'preview' : 'Vista previa del'
      }
    ]
  },
  {
    type: 'img',
    imageData: getLocalImage(imagesData, 'mokens-universe/stadiumLogos/Helios-coliseum-stadium.png', 'imageData'),
    title: 'mokensUniverse.stadiums.heliosColiseum',
    altAid: language === 'en' ? 'logo' : 'Logo del',
    screenshots: [
      {
        type: 'video',
        videoUrl: S3_BUCKET_URL + 'videos/HeliosTurnaround.mp4',
        title: 'mokensUniverse.stadiums.heliosColiseum',
        altAid: language === 'en' ? 'video' : 'Video del'
      },
      {
        type: 'img',
        imageData: getLocalImage(imagesData, 'mokens-universe/stadiumPreviews/HeliosPreview.png', 'imageData'),
        title: 'mokensUniverse.stadiums.heliosColiseum',
        altAid: language === 'en' ? 'preview' : 'Vista previa del'
      }
    ]
  },
  {
    type: 'img',
    imageData: getLocalImage(imagesData, 'mokens-universe/stadiumLogos/Pixel-world-stadium.png', 'imageData'),
    title: 'mokensUniverse.stadiums.pixelWorld',
    altAid: language === 'en' ? 'logo' : 'Logo del',
    screenshots: [
      {
        type: 'video',
        videoUrl: S3_BUCKET_URL + 'videos/PixelWorldTurnaround.mp4',
        title: 'mokensUniverse.stadiums.pixelWorld',
        altAid: language === 'en' ? 'video' : 'Video del'
      },
      {
        type: 'img',
        imageData: getLocalImage(imagesData, 'mokens-universe/stadiumPreviews/PixelWorldPreview.png', 'imageData'),
        title: 'mokensUniverse.stadiums.pixelWorld',
        altAid: language === 'en' ? 'preview' : 'Vista previa del'
      }
    ]
  },
  // {
  //   type: 'img',
  //   imageData: getLocalImage(imagesData, 'mokens-universe/stadiumLogos/Frostgard-ace-stadium.png', 'imageData'),
  //   title: 'mokensUniverse.stadiums.frostgardAce',
  //   altAid: language === 'en' ? 'logo' : 'Logo del',
  //   screenshots: [
  //     {
  //       type: 'img',
  //       imageData: getLocalImage(imagesData, 'mokens-universe/stadiumPreviews/FrostgardAcePreview.png', 'imageData'),
  //       title: 'mokensUniverse.stadiums.frostgardAce',
  //       altAid: language === 'en' ? 'preview' : 'Vista previa del'
  //     }
  //   ]
  // },
  {
    type: 'img',
    imageData: getLocalImage(imagesData, 'mokens-universe/stadiumLogos/Mech-mania-arena-stadium.png', 'imageData'),
    title: 'mokensUniverse.stadiums.mechManiaArena',
    altAid: language === 'en' ? 'logo' : 'Logo del',
    screenshots: [
      {
        type: 'video',
        videoUrl: S3_BUCKET_URL + 'videos/MechManiaTurnaround.mp4',
        title: 'mokensUniverse.stadiums.mechManiaArena',
        altAid: language === 'en' ? 'video' : 'Video del'
      },
      {
        type: 'img',
        imageData: getLocalImage(imagesData, 'mokens-universe/stadiumPreviews/MechmaniaPreview.png', 'imageData'),
        title: 'mokensUniverse.stadiums.mechManiaArena',
        altAid: language === 'en' ? 'preview' : 'Vista previa del'
      }
    ]
  },
  {
    type: 'img',
    imageData: getLocalImage(imagesData, 'mokens-universe/stadiumLogos/Valparadiso-stadium.png', 'imageData'),
    title: 'mokensUniverse.stadiums.valparadiso',
    altAid: language === 'en' ? 'logo' : 'Logo del',
    screenshots: [
      {
        type: 'video',
        videoUrl: S3_BUCKET_URL + 'videos/ValparadisoTurnaround.mp4',
        title: 'mokensUniverse.stadiums.valparadiso',
        altAid: language === 'en' ? 'video' : 'Video del'
      },
      {
        type: 'img',
        imageData: getLocalImage(imagesData, 'mokens-universe/stadiumPreviews/ValparadisoPreview.png', 'imageData'),
        title: 'mokensUniverse.stadiums.valparadiso',
        altAid: language === 'en' ? 'preview' : 'Vista previa del'
      }
    ]
  },
  {
    type: 'img',
    imageData: getLocalImage(imagesData, 'mokens-universe/stadiumLogos/Nebula-stadium.png', 'imageData'),
    title: 'mokensUniverse.stadiums.nebula',
    altAid: language === 'en' ? 'logo' : 'Logo del',
    screenshots: [
      {
        type: 'video',
        videoUrl: S3_BUCKET_URL + 'videos/NebulaTurnaround.mp4',
        title: 'mokensUniverse.stadiums.nebula',
        altAid: language === 'en' ? 'video' : 'Video del'
      },
      {
        type: 'img',
        imageData: getLocalImage(imagesData, 'mokens-universe/stadiumPreviews/NebulePreview.png', 'imageData'),
        title: 'mokensUniverse.stadiums.nebula',
        altAid: language === 'en' ? 'preview' : 'Vista previa del'
      }
    ]
  },
  {
    type: 'img',
    imageData: getLocalImage(imagesData, 'mokens-universe/stadiumLogos/Frostgard-stadium.png', 'imageData'),
    title: 'mokensUniverse.stadiums.frostgard',
    altAid: language === 'en' ? 'logo' : 'Logo del',
    screenshots: [
      {
        type: 'video',
        videoUrl: S3_BUCKET_URL + 'videos/FrostgardTurnaround.mp4',
        title: 'mokensUniverse.stadiums.frostgard',
        altAid: language === 'en' ? 'video' : 'Video del'
      },
      {
        type: 'img',
        imageData: getLocalImage(imagesData, 'mokens-universe/stadiumPreviews/FrostgardPreview.png', 'imageData'),
        title: 'mokensUniverse.stadiums.frostgard',
        altAid: language === 'en' ? 'preview' : 'Vista previa del'
      }
    ]
  },
  {
    type: 'img',
    imageData: getLocalImage(imagesData, 'mokens-universe/stadiumLogos/Lumen-arena-stadium.png', 'imageData'),
    title: 'mokensUniverse.stadiums.lumenArena',
    altAid: language === 'en' ? 'logo' : 'Logo del',
    screenshots: [
      {
        type: 'video',
        videoUrl: S3_BUCKET_URL + 'videos/LumenTurnaround.mp4',
        title: 'mokensUniverse.stadiums.lumenArena',
        altAid: language === 'en' ? 'video' : 'Video del'
      },
      {
        type: 'img',
        imageData: getLocalImage(imagesData, 'mokens-universe/stadiumPreviews/LumenPreview.png', 'imageData'),
        title: 'mokensUniverse.stadiums.lumenArena',
        altAid: language === 'en' ? 'preview' : 'Vista previa del'
      }
    ]
  },
  {
    type: 'img',
    imageData: getLocalImage(imagesData, 'mokens-universe/stadiumLogos/Marina-bay-stadium.png', 'imageData'),
    title: 'mokensUniverse.stadiums.marinaBay',
    altAid: language === 'en' ? 'logo' : 'Logo del',
    screenshots: [
      {
        type: 'video',
        videoUrl: S3_BUCKET_URL + 'videos/MarinaBayTurnaround.mp4',
        title: 'mokensUniverse.stadiums.marinaBay',
        altAid: language === 'en' ? 'video' : 'Video del'
      },
      {
        type: 'img',
        imageData: getLocalImage(imagesData, 'mokens-universe/stadiumPreviews/MarinaBayPreview.png', 'imageData'),
        title: 'mokensUniverse.stadiums.marinaBay',
        altAid: language === 'en' ? 'preview' : 'Vista previa del'
      }
    ]
  },
  // {
  //   type: 'img',
  //   imageData: getLocalImage(imagesData, 'mokens-universe/stadiumLogos/Elios-Brawl-stadium.png', 'imageData'),
  //   title: 'mokensUniverse.stadiums.eliosBrawl',
  //   altAid: language === 'en' ? 'logo' : 'Logo del',
  //   screenshots: [
  //     {
  //       type: 'img',
  //       imageData: getLocalImage(imagesData, 'mokens-universe/stadiumPreviews/HeliosBrawlPreview.png', 'imageData'),
  //       title: 'mokensUniverse.stadiums.eliosBrawl',
  //       altAid: language === 'en' ? 'preview' : 'Vista previa del'
  //     }
  //   ]
  // }
]

  return (
    <>
      <BackgroundImage
        id={MOKENS_UNIVERSE_SECTION_ID}
        Tag='section'
        fluid={getLocalImage(imagesData, 'sections-backgrounds/Stadiums-background.png', 'fluidData')}
      >
        <StaticImage
          src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/mokens-universe-logo.png'
          alt={t('mokensUniverse.logo')}
          className='
            absolute
            w-[260px]
            top-3
            left-1/2
            transform
            -translate-x-1/2
            z-10
            lg:w-[700px]
            lg:-top-[90px]
          '
          placeholder='none'
        />
        <div className='h-full flex flex-col justify-end pb-[10%] pt-[175px] lg:pt-[300px] lg:pb-[50px]'>
          <SwipeSlider
            slidesPerView={isMobile ? 2 : 4}
            centeredSlides
            loop
            className='main-slider'
            slides={sliderData}
            setCurrentGallery={(gallery: ISlide[]) => openGallery(gallery)}
            spaceBetween={isMobile && -25}
          />
        </div>
        <Modal
          open={isModalOpen}
          onClose={(event, reason) => handleClose(event, reason)}
          className='flex items-center justify-center'
        >
          <>
            <div className='w-4/5 flex items-center'>
              <SwipeSlider
                spaceBetween={500}
                slides={selectedGallery}
                navigation
                loop
                centeredSlides
              >
                <SwiperNavButtons />
              </SwipeSlider>
            </div>
            {
              !isMobile &&
              (
                <IconButton
                  className='
                    absolute
                    right-4
                    top-4
                    w-28
                  '
                  onClick={() => setModalState(false)}>
                  <div className='w-full z-10'>
                    <img src={CloseIcon} alt={t('mokensUniverse.closeIcon')} />
                  </div>
                </IconButton>
              )
            }
          </>
        </Modal>
      </BackgroundImage>
    </>
  )
}

export default MokensUniverse;
