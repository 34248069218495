import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import useMetriCool from 'common/useMetriCool';
import toast, { Toaster } from 'react-hot-toast';

import { useUserContext } from 'common/UserProvider';
import {
	COMMUNITY_SECTION_ID,
	FAQ_SECTION_ID,
	MOKENS_UNIVERSE_SECTION_ID,
	THE_MOKENS_SECTION_ID,
	MARKETPLACE_SECTION_ID,
	ALBUM_SECTION_ID,
	TOURNAMENTS_SECTION_ID
} from 'common/const';
import { FooterTheme } from 'common/context';
import { FooterStyle } from 'common/types';
import { init } from 'common/handleLogin';

import SEO from '@layout/SEO';
import Header from '@layout/Header';
import Footer from '@layout/Footer';
// import Main from '@sections/Main';
// import PrivateSaleSection from '@sections/PrivateSaleSection';
// import NFTsSection from '@sections/NFTsSection';
// import PlayToEarnSection from '@sections/PlayToEarnSection';
// import TokenomicsSection from '@sections/TokenomicsSection';
import Hero from '@sections/HeroSection';
import FAQ from '@sections/FAQSection';
import MokensUniverse from '@sections/MokensUniverseSection';
import JoinOurCommunity from '@sections/JoinOurCommunitySection';
import Marketplace from '@sections/Marketplace';
import TheMokens from '@sections/TheMokens';
import AlbumAndTournaments from '@sections/AlbumAndTournaments';

import metaImgPath from 'images/meta-img.png';

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
    allS3Object {
      nodes {
        localFile {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allFile {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
	}
`;

const LandingPage = ({ data }) => {
	useMetriCool();
	const { t } = useTranslation();
	const { language, languages } = useI18next();

	const [error, setError] = useState('');
	const { user, setUser } = useUserContext();

	const links = [
		{ title: 'layout.mokens', url: `/#${THE_MOKENS_SECTION_ID}` },
		{ title: 'layout.marketplace', url: `/#${MARKETPLACE_SECTION_ID}` },
		{ title: 'layout.album', url: `/#${ALBUM_SECTION_ID}` },
		{ title: 'layout.tournaments', url: `/#${TOURNAMENTS_SECTION_ID}` },
		{ title: 'layout.mokensUniverse', url: `/#${MOKENS_UNIVERSE_SECTION_ID}` },
		{ title: 'layout.community', url: `/#${COMMUNITY_SECTION_ID}` },
		{ title: 'layout.faq', url: `/#${FAQ_SECTION_ID}` }
	];

	useEffect(() => {
		if (error) toast.error(error);
	}, [error]);

	useEffect(() => {
		if (window.location) {
			init(language, languages).then(res => {
				if (res.error) setError(res.error);
				if (res.user) setUser(res.user);
			}).catch(err => { return err })
		}
	}, []);

	return (
		<>
			<SEO
				title={t('page.title')}
				description={t('comingSoon.pageDescription')}
				lang='en'
				imgPath={metaImgPath}
				imgAlt=''
				meta={[]}
			/>
			<Toaster />
			<main className='overflow-x-hidden'>
				<div className='
          fixed
          z-50
          top-0
          left-0
          right-0
          lg:absolute
        '
				>
					<Header />
				</div>
				{/* <Toaster />
				 <Main />
				<PrivateSaleSection />
				<NFTsSection />
				<PlayToEarnSection /> */}
				{/* <TeamSection /> */}
				{/*<TokenomicsSection />*/}
				<Hero />
				<TheMokens imagesData={data} />
				<Marketplace imagesData={data} />
				<AlbumAndTournaments imagesData={data} isUserLogged={user} />
				<MokensUniverse imagesData={data} />
				<JoinOurCommunity imagesData={data} />
				<FAQ />
				<FooterTheme.Provider value={FooterStyle.DARK}>
					<Footer links={links} />
				</FooterTheme.Provider>
			</main>
		</>
	);
};

export default LandingPage;
