import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useMediaChange from 'common/useMediaChange';

import { THE_MOKENS_SECTION_ID } from 'common/const';
import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';

import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';

import './styles.scss';

const TheMokens: FC<ISectionQueryData> = ({ imagesData }) => {
	const { t } = useTranslation();
	const { isDesktop } = useMediaChange();

	const theMokensSecondSectionBg = [
		'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 75%, rgba(0,212,255,0) 100%)',
		getBucketImage(imagesData, 'mokens-match.png', 'fluidData'),
	]

	return (
		<>
			<BackgroundImage
				id={THE_MOKENS_SECTION_ID}
				Tag='section'
				fluid={getBucketImage(imagesData, 'crossed-neon-lights.png', 'fluidData')}
				className='lineup-background'
			>
				<div className='
						h-full
						flex
						flex-col
						items-center
						safe-space-container
					'
				>
					<StaticImage
						placeholder='none'
						src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/the-mokens-logo.png'
						alt={t('theMokensSection.logoAlt')}
						className='w-2/5'
					/>
					<p className='the-mokens-description the-mokens-description-first-section'>
						{t('theMokensSection.title')}
					</p>
					<StaticImage
						placeholder='none'
						src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/mokens-group-photo.png'
						alt={t('theMokensSection.lineupAlt')}
					/>
				</div>
			</BackgroundImage>
			{
				isDesktop
					? <BackgroundImage
						Tag='section'
						fluid={theMokensSecondSectionBg}
						className='cards-section-background'
					>
						<div className='flex flex-col safe-space-container'>
							<div className='max-w-[500px] space-y-4'>
								<h1 className='the-mokens-title'>
									{t('theMokensSection.promoTitle')}
								</h1>
								<p className='the-mokens-description the-mokens-description-second-section'>
									{t('theMokensSection.promoText1')}
								</p>
								<p className='the-mokens-description the-mokens-description-second-section'>
									{t('theMokensSection.promoText2')}
								</p>
							</div>
						</div>
					</BackgroundImage>
					: <>
						<section className='match-background'>
							<div className='w-[90%] max-w-[640px] flex flex-col'>
								<div className='flex flex-col justify-center items-center space-y-4 py-[5%]'>
									<h1 className='the-mokens-title'>
										{t('theMokensSection.promoTitleMobile')}
									</h1>
									<p className='the-mokens-description the-mokens-description-second-section'>
										{t('theMokensSection.promoText1')}
									</p>
									<p className='the-mokens-description the-mokens-description-second-section'>
										{t('theMokensSection.promoText2')}
									</p>
								</div>
							</div>
						</section>
						<StaticImage
							src='https://mokens-landing-bucket.s3.amazonaws.com/images/backgrounds/mokens-match.png'
							alt={t('theMokensSection.matchAlt')}
						/>
					</>
			}
		</>
	)
}

export default TheMokens;
